/** @type { EnumInterface } */
const useEnums = {};

export function setEnums(options) {
  for (const key in options) {
    useEnums[key] = new Proxy(options[key], {
      get(target, prop) {
        if (typeof prop !== "string") {
          return false;
        }

        const normalisedKey = prop.replaceAll(" ", "");

        if (Reflect.has(target, normalisedKey)) {
          return Reflect.get(target, normalisedKey);
        }

        return false;
      },
    });
  }

  // Prevent any mutations.
  Object.freeze(useEnums);
}

export default function () {
  return useEnums;
}
