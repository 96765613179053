import "../css/app.css";
import { createApp, h } from "vue";
import { createInertiaApp, usePage } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "ziggy-js";
import InstantSearch from "vue-instantsearch/vue3/es";
import * as Sentry from "@sentry/vue";
import FloatingVue from "floating-vue";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import useEnums, { setEnums } from "@/composables/useEnums.js";

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler
);

Chart.defaults.font.family = "Lato, Karla";
Chart.defaults.datasets.line = {
  borderWidth: 1,
};

const [ziggyResponse, enumResponse] = await Promise.all([
  fetch("/internal-api/ziggy"),
  fetch("/internal-api/options"),
]);

const [Ziggy, Enums] = await Promise.all([
  ziggyResponse.json(),
  enumResponse.json(),
]);

const enumPlugin = {
  install(app) {
    setEnums(Enums);
    app.provide("enums", useEnums());
  },
};

const gfyChrome129 = createInertiaApp({
  title: (title) => title,

  resolve: async (name) => {
    return (
      await resolvePageComponent(
        `./Pages/${name}.vue`,
        import.meta.glob("./Pages/**/*.vue")
      )
    ).default;
  },
  setup({ el, App, props, plugin }) {
    const theApp = createApp({ render: () => h(App, props) });

    if (
      ["production", "staging"].includes(
        document.querySelector('meta[name="sentry-environment"]')?.content
      )
    ) {
      const propagationTargets = document
        .querySelector('meta[name="sentry-propagation-targets"]')
        .content.split(",")
        .map((r) => new RegExp(r));

      Sentry.init({
        app: theApp,
        dsn: document.querySelector('meta[name="sentry-public-dsn"]').content,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],

        environment: document.querySelector('meta[name="sentry-environment"]')
          .content,
        release: document.querySelector('meta[name="sentry-release"]').content,
        tracesSampleRate: document.querySelector(
          'meta[name="sentry-sample-rate"]'
        ).content,
        tracePropagationTargets: propagationTargets,
        replaysSessionSampleRate: document.querySelector(
          'meta[name="sentry-replay-sample-rate"]'
        ).content,
        replaysOnErrorSampleRate: 1.0,
      });
    }

    theApp.directive("can", {
      mounted(el, binding) {
        const requiredPermissions = Array.isArray(binding.value)
          ? binding.value
          : [binding.value];

        if (
          !requiredPermissions.every((perm) =>
            usePage().props.auth.user.permissions.includes(perm)
          )
        ) {
          const meta = document.createElement("meta");
          meta.name = "denied";
          el.parentNode?.replaceChild(meta, el);
        }
      },
    });

    theApp.directive("cannot", {
      mounted(el) {
        if (el.previousElementSibling?.name === "denied") {
          el.previousElementSibling.remove();
        } else {
          el.parentNode?.replaceChild(document.createComment(" "), el);
        }
      },
    });

    theApp.directive("tooltip", FloatingVue.vTooltip);

    theApp
      .use(plugin)
      .use(ZiggyVue, Ziggy)
      .use(enumPlugin)
      .use(InstantSearch)
      .use(FloatingVue, {
        themes: {
          tooltip: {
            triggers: ["hover", "focus"],
            placement: "top",
            strategy: "fixed",
            distance: 10,
          },
        },
      })
      .mount(el);
  },
});

/**
 * For some reason Chrome 129 specifically is struggling
 * with the setup function in inertia. Using the below
 * lines of code (and adding the const above) means that
 * it works in Chrome 129 and no console log is actually output.
 *
 * Testing in Chrome 130 confirms it is working as normal so
 * remove this code in 2025.
 */
if (Math.random() < 0) {
  // eslint-disable-next-line no-console
  console.log(gfyChrome129);
}
